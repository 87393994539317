.not-found{
    margin: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgb(77, 194, 155);
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-found-content{
    width: 50%;
    height: 50%;
    /* background-color: #2A9F92; */
    display: flex;
    flex-direction: column;
}
.not-found-title{
    width: 100%;
    height: 60%;
    color: rgba(255, 255, 255, 0.795);
    font-size: 20rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}
.not-found-subtitle{
    color: #fff;
    display: flex;
    font-size: 3rem;
}
.not-found-text{
    color: #fff;
    font-size: 2rem;
}
.not-found-text a{
    text-decoration: none;
    color: aquamarine;
}