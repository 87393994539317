.about-page{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}
.about-first-block{
    padding-top: 30px;
    width: 100%;
    height: 140%;
    background:url('../../media/about_figure.svg');
    background-repeat: no-repeat;
    background-position: 0% 75%;
    background-size: 60% 60%;
    display: flex;
    justify-content: center;
}
.first-content{
    width: 90%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(11, 1fr);
    background:radial-gradient(27% 27% at 70% 30%, #2A9F92 0%, rgba(127, 206, 197, 0) 100%) ;
}
.first-text{
    grid-column: 1/7;
    grid-row: 1/5;
    display: flex;
    flex-direction: column;
}
.first-text-title{
    width: 100%;
    height: 20%;
    color: #18545B;
    font-size: 42px;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.first-text-text{
    font-size: 22px;
    line-height: 32px;
}
.first-image{
    display: flex;
    justify-content: center;
    grid-column: 6/13;
    grid-row: 1/6;
}
.first-image img{
    width: auto;
    height: auto;
    max-width: 100%; 
}
.first-mtmk{
    grid-column: 1/7;
    grid-row: 6/11;
    display: flex;
    justify-content: center;
    align-items: center;
}
.first-mtmk img{
    width: 90%;
    height: auto;
}
.first-mtmk-text{
    grid-column: 7/13;
    grid-row: 6/9;
}
.second-mtmk-text{
    grid-column: 7/13;
    grid-row: 8/11;
    padding-top: 0%;
}
.about-photos{
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;

}
.about-photos-content{
    width: 85%;
    height: 100%;
    background-image: url('../../media/photos.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size:100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.about-photo{
    width: auto;
    height: auto;
    max-height: 70%;
    box-sizing: border-box;
    border-radius: 25px;
}
.about-photo img{
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.about-fbm{
    width: 100%;
    height: 130%;
    background-image: url('../../media/rectangle.svg');
    background-repeat: no-repeat;
    background-position: 0% 75%;
    background-size: cover;
    box-sizing: border-box;

}
.about-fbm-gradient{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background: linear-gradient(360deg, #ffffff 47%, transparent 75%),linear-gradient(180deg, #ffffff 5%, transparent 35%),linear-gradient(90deg, #ffffff 50%, transparent 70%);
}
.fbm-content{
    width: 90%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(9, 1fr);
    padding-top: 50px;
    box-sizing: border-box;
}
.fbm-text{
    grid-column: 1/7;
    grid-row: 1/6;
}
.first-text-text a{
    color:#18545B
}
.fbm-image{
    grid-column: 8/13;
    grid-row: 2/5;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fbm-image{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.flg-image{
    grid-column: 2/6;
    grid-row: 6/11;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.service{
    grid-column: 7/13;
    grid-row: 6/11;
}
.report{
    width: 100%;
    height: 70%;
    background-image:url('../../media/report_background.svg');
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 90% 100%;
    display: flex;
    justify-content: center;
}
.report-content{
    width: 90%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(10, 1fr);
}
.report-text{
    grid-column: 1/7;
    grid-row: 1/13;
}
.report-text>.first-text-title{
    height: 25%;
}
.report-text>.first-text-text{
    height: auto;
}
.report-video{
    grid-column: 7/13;
    grid-row: 1/13;
    display: flex;
    justify-content: center;
    padding-top: 20%;
}
.rounded-video{
    width: auto;
    height: auto;
}
.toser-advantages{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    background:url('../../media/toser_background.svg'),radial-gradient(30% 45% at 70% 60%, #74bbb3 0%, transparent 100%);
    background-repeat: no-repeat;
    background-position: 150% 40%;
    background-size: 70% 70%;
}
.toser{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.toser-content{
    height: 100%;
    width: 90%;
    padding-bottom: 70px
}
.toser-title{
    width: 100%;
    height: auto;
    padding-bottom: 50px;
    color: #18545B;
    font-size: 42px;
    font-weight: 700;
    display: flex;
    align-items: center;
}
.toser-text{
    width: 100%;
    height: auto;
    color: #000;
    font-size: 20px;
    padding-bottom: 45px;
    line-height: 32px;

}
.toser-adv-chistai{
    width: 100%;
    height: auto;
    display: flex;
}
.toser-adv{
    width: 100%;
    height: auto;
    max-width: 65%;
    max-height: 70%;
    padding: 25px;
    box-sizing: border-box;
    border-radius: 20px;
    border: 1px solid #2A9F92;
    display: flex;
    flex-direction: column;
}
.advan{
    width: 100%;
    height: auto;
    display: flex;
    padding: 10px 0px;
}
.adv-dot{
    width: auto;
    height: auto;
    color: #2A9F92;
    padding-top: 6px;
}
.advant-text{
    width: auto;
    padding-left: 20px;
    height: auto;
    color:#000;
    font-size: 20px;
    line-height: 32px;

}
.toser-chistai{
    width: 35%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 60px;
}
.toser-chistai img{
    width: auto;
    height: auto;
    max-width: 100%;
}
.patents{
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
}
.patents-content{
    width: 90%;
    height: 100%;
}
.patents-title{
    width: 100%;
    height: 12%;
    color: #18545B;
    font-size: 36px;
    font-weight: 700;
    display: flex;
    align-items: flex-start;
}
.patents-text{
    width: auto;
    height: auto;
    color: #000;
    font-size: 20px;
    padding-bottom: 30px;
    max-width: 50%;
    line-height: 32px;
}
.patents-list{
    width: auto;
    height: auto;
    max-width: 70%;
}
.patents-images{
    width:100%;
    height: auto;
    display: flex;
    margin-bottom: 110px;
    justify-content: center;
    background-image: linear-gradient(180deg, #FFFFFF 0%, #4FB8B6 45%,#4FB8B6 55%, rgba(217, 217, 217, 0) 100%);;
}
.patents-images img{
    height: auto;
    width: auto;
    max-width: 90%;
}





@media screen and (max-width: 1024px){
    .first-text{grid-row: 1/8;}
    .first-image{
        grid-row: 2/6;
        grid-column: 7/13;
    }
    .about-first-block{
        padding: 0;
    }
    .first-image img{
        width: 100%;
        height: auto;
        max-width: 100%;
    }
    .first-content{
        background: radial-gradient(35% 25% at 67% 30%, #2A9F92 0%, rgba(127, 206, 197, 0) 100%);
    }
    .first-text-title{
        font-size: 30px;
    }
    .first-text-text{
        font-size: 16px;
    }
    .about-photos-content{
        width: 100%;
    }
    .about-photo{
        max-width: 30%;
        max-height: 60%;
        overflow: hidden;
    }
    .fbm-image img,.flg-image img{
        max-width: 100%;
    }
    .flg-image{
        display: flex;
        align-items: center;
    }
    .rounded-video{
        max-width: 100%;
    }
    .vid{
        max-width: 100%;
        height: auto;
    }
    .report-video{
        padding-top: 34%;
    }
    .toser-content{width: 100%;}
    .toser-chistai{
        padding: 60px 0px 0px 20px;
    }
    .toser-title{
        font-size: 30px;
    }
    .toser-text,.advant-text,.patents-text{
        font-size: 16px;
    }
    .patents-text,.patents-list{
        min-width: 90%;
    }
}
@media screen and (max-width: 800px){
    .first-text{
        grid-column: 1/13;
        grid-row: 1/4;
    }
    .first-image{
        grid-row: 4/6;
        grid-column: 3/10;
    }
    .first-image img{
        height: auto;
        width: 100%;
        max-width: 100%;
    }
    .first-content{
        background: radial-gradient(30% 15% at 42% 39%, #2A9F92 0%, rgba(127, 206, 197, 0) 100%);
    }
    .about-first-block{
        background-position: 0% 120%;
        background-size: 80% 80%;

    }
    .first-mtmk{
        grid-row: 7/10;
        grid-column: 1/13;
    }
    .first-mtmk img{
        max-height: 100%;
        width: auto;
    }
    .about-first-block{
        height: 180%;
    }
    .first-mtmk-text{
        grid-column: 1/13;
        grid-row: 6/8;
    }
    .second-mtmk-text{
        grid-column: 1/13;
        grid-row: 10/13;
        padding-top: 0%;
    }
    .about-photos-content{
        height: 80%;
    }
    .fbm-content{
        padding: 0;
    }
    
    .about-fbm{
        height: 180%;
    }
    .fbm-text{
        grid-column: 1/13;
        grid-row: 1/3;
    }
    .service{
        grid-column: 1/13;
        grid-row: 6/8;
    }
    .fbm-image{
        grid-row: 4/6;
        grid-column: 4/10;
    }
    .about-fbm-gradient{
        background: linear-gradient(360deg, #ffffff 40%, transparent 50%),linear-gradient(180deg, #ffffff 30%, transparent 40%),linear-gradient(90deg, #ffffff 10%, transparent 50%);
    }
    .flg-image{
        grid-column: 4/10;
        grid-row: 8/10;
    }
     img{
        max-height: 100%;
    }
    .report{
        height: 100%;
    }
    .report-text{
        padding-top: 20px;
        grid-column: 1/13;
        grid-row: 1/6;
    }
    .report-text>.first-text-text{
        padding-top: 15px;
    }
    .report-video{
        grid-column: 1/13;
        grid-row: 5/12;
        padding: 0;
    }
    .toser-advantages{
        background: url('../../media/toser_background.svg'),radial-gradient(54% 36% at 70% 45%, #74bbb3 0%, transparent 100%);
        background-repeat: no-repeat;
        background-position: 150% 40%;
        background-size: 70% 70%;
    }

}
@media screen and (max-width: 550px){
    .first-text-title{
        padding: 10px 0px;
        font-size: 24px;
    }
    .first-text-text{
        font-size: 14px;
    }
    .fbm-image{
        align-items: flex-end;
    }
    .about-fbm-gradient{
        background: linear-gradient(360deg, #ffffff 37%, transparent 50%),linear-gradient(180deg, #ffffff 35%, transparent 50%),linear-gradient(90deg, #ffffff 10%, transparent 50%);
    }
    .toser-adv-chistai{
        flex-direction: column;
    }
    .toser-adv{
        max-width: 100%;
    }
    .toser-chistai{
        width:100%;
        padding-left: 0px;
    }
    .toser-advantages{
        background: url('../../media/toser_background.svg'),radial-gradient(54% 36% at 70% 40%, #74bbb3 0%, transparent 100%);
        background-repeat: no-repeat;
        background-position: 150% 40%;
        background-size: 70% 60%;
    }
    .patents-content{
        width: 100%;
    }
    .patents-text, .patents-list{
        width: 100%;
        min-width: 100%;
    }
}
@media screen and (max-width: 425px){
    .first-content{
        background: radial-gradient(40% 13% at 42% 39%, #2A9F92 0%, rgba(127, 206, 197, 0) 100%);
    }
    .first-mtmk{
        grid-row: 8/10;
    }
    .first-mtmk img{
        max-height: 100%;
    }
    .footer-content{
        flex-direction: column;
    }
    .first-image{
        grid-column: 2/12;
    }
    .about-photos-content{
        flex-direction: column;
    }
    .about-photos{
        height: 90%;
    }
    .flg-image{
        padding-top: 60px;
        display: flex;
        align-items: center;
    }
    .about-photo{
        max-width: 60%;
    }
    .report{
        height: 90%;
        padding-bottom: 30px;
        box-sizing: border-box;
    }
    .vid{
        max-height: 100%;
    }
    .report-video{
        grid-row: 6/12
    }
    .toser-advantages {
        background: url('../../media/toser_background.svg'),radial-gradient(54% 20% at 80% 45%, #74bbb3 0%, transparent 100%);
        background-repeat: no-repeat;
        background-position: 150% 40%;
        background-size: 70% 60%;
    }
    .about-fbm-gradient {
        background: linear-gradient(360deg, #ffffff 40%, transparent 50%),linear-gradient(180deg, #ffffff 45%, transparent 51%),linear-gradient(90deg, #ffffff 10%, transparent 30%);
    }
}
@media screen and (max-width: 320px){
    .first-text-title{
        font-size: 20px;
    }
    .first-text-text{
        font-size: 1px;
    }
}