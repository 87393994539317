.footer{
    width: 100%;
    height: 30%;
    max-height: 30%;
    overflow:hidden;
}
.footer-content{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1D5F64;
}
.footer-contacts{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: flex-end;
    padding-left: 100px;
}
.footer-contacts-title{
    width: auto;
    height: auto;
    font-size: 30px;
    color: #FFFFFF;
    font-weight: 600;
    padding: 25px 0px;
}
.footer-contacts-info{
    width: auto;
    box-sizing: border-box;
    padding-top: 0px;
    max-width: 60%;
    height:65%;
    max-height:65%;
}
.contacts-info{
    display: flex;
    height: auto;
    min-height: 15%;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 20px;
    padding: 8px 0px;
    align-items: center;
}
.contacts-info-icon{
    display: flex;
    height: 100%;
    width: auto;
    align-items: flex-start;
}
.contacts-info-text{
    display: flex;
    padding-left: 10px;
    height: 100%;
    align-items: flex-start;
}
.footer-comp{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}
.comp-logo{
    height: 75%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;
    box-sizing: border-box;
    align-items: center;
}
.comp-logo img{
    transform: scale(0.9);
}
.comp-text{
    width: 80%;
    height: 25%;
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    padding-right: 110px;
    box-sizing: border-box;
    justify-content: space-between;
}



@media screen and (max-width: 1450px){
    .footer-contacts{
        padding-left: 50px;
    }
    .footer-contacts-info{
        max-width: 90%;
    }
    .comp-logo img{
        max-width: 100%;
    }
    .comp-text{
        width: auto;
        flex-direction: column;
        padding: 0;
        padding-left: 4%;
    }
    .comp-logo{
        height: auto;
        padding-bottom: 25px;
    }
    .footer-comp{
        align-items: flex-start;
        padding-top: 25px;
        box-sizing: border-box;
    }
    .comp-text-tattelemed{
        padding-bottom: 10px;
    }
}
@media screen and (max-width: 800px){
    .footer-comp{
        width: 40%;
    }
    .comp-logo{
        padding: 0px 25px 25px 0px;
    }
    .footer-contacts{
        width: 60%;
        box-sizing: border-box;
        justify-content: flex-start;
    }
    .footer-contacts-title{
        box-sizing: border-box;
    }
    .footer-contacts-title{
        font-size: 24px;
        padding: 15px 0px;
    }
    .contacts-info-text{
        font-size: 15px;
    }
    .comp-text{
        font-size: 15px;
    }
}
@media screen and (max-width: 425px){
    .footer{
        height: 60%;
        max-height: 60%;
    }
    .footer-contacts{
        width: 80%;
        padding: 0;
    }
    .footer-comp{
        width: 90%;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .comp-logo{
        padding: 0;
    }
}
@media screen and (max-width: 320px){
}