.contacts-page{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}
.contacts-content{
    width: 100%;
    max-width: 100%;
    height: 87%;
    display: flex;
    background-image: url('../../media/map.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.top-gradient{
    width: 100%;
    height: 100%;
    background: linear-gradient(-180deg, rgba(42, 159, 146, 0.4) 10%, transparent 30%);
}
.left-gradient{
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(42, 159, 146, 1) 30%, transparent 60%);
}
.contact-text{
    display: flex;
    flex-direction: column;
    width: 38%;
    max-width: 40%;
    height: 50%;
    box-sizing: border-box;
}
.contact-text-title{
    width: 100%;
    height: 30%;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    font-size: 2.7rem;
    font-weight: 700;
    color: #fff;
    padding-left: 14%;
}
.contact-text-subtitle{
    padding-bottom: 25px;
    width: 100%;
    height: 20%;
    font-size: 1.7rem;
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: flex-end;
    padding-left: 14%;
}
.contact-text>.footer-contacts-info{
    padding-left: 14%;
    max-width: 80%;
}
.contact-text>.footer-contacts-info{
    height: auto;
}
.contact-text>.footer-contacts-info>.contacts-info{
    padding: 10px 0px;
}
.contact-image{
    width: 50%;
    height: 45%;
}
.contact-image{
    background-image: url('../../media/contact_figure.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.contact-image img{
    padding-left: 10%;
}
.cont-footer{
    width: 100%;
    height: 20%;
    min-height: 180px;
    display: flex;
    max-width: 100%;
    background-color: #1D5F64;
    padding-left: 5%;
}
.cont-footer-content{
    width: 50%;
    height: 100%;
    color: #fff;
}
.cont-footer-logo{
    width: 100%;
    height: 70%;
    display: flex;
    box-sizing: border-box;
    padding: 20px 0px;
    justify-content: flex-start;
    align-items: center;
}
.cont-footer-logo img{
    max-height: 100%;
}
.cont-footer-text{
    width: 100%;
    height: 30%;
    display: flex;
}
.cont-footer-text-vector{
    margin-left: 4%;
}

@media screen and (max-width: 1024px){
    .left-gradient{
        background: linear-gradient(90deg, rgba(42, 159, 146, 1) 55%, transparent 100%);
    }
    .contact-text{
        width: 60%;
        max-width: 60%;
    }
    .cont-footer-content{
        width: 90%;
    }
}
@media screen and (max-width: 800px){
    .left-gradient{
        background: linear-gradient(90deg, rgba(42, 159, 146, 1) 65%, transparent 100%);
        background-repeat: no-repeat;
    }
    .contact-text{
        width: 65%;
        max-width: 65%;
    }
    .contact-text-title{
        font-size:28px;
    }
    .contact-text-subtitle{
        font-size: 22px;
    }
}
@media screen and (max-width: 550px){
    
    .top-gradient{
        box-sizing: border-box;
        max-height: 100%;
        min-height: 100%;
    }
    .left-gradient{
        box-sizing: border-box;
        background: linear-gradient(90deg, rgba(42, 159, 146, 1) 100%, transparent 100%);
    }
    .contact-text{
        box-sizing: border-box;
        width: 90%;
        max-width: 90%;
        height: 50%;
    }
    .contact-text-subtitle,.contact-text>.footer-contacts-info{
        padding: 5px 0% 5px 5%;
        box-sizing: border-box;
        max-width: 100%;
    }
    .contact-text-title{
        align-items: center;
        height: auto;
        padding: 40px 5%;
    }
    .contact-image{
        width: 90%;
        padding: 60px 5% 0px 5%;
        box-sizing: border-box;
    }
    .contact-image img{
        max-width: 100%;
        padding: 0;
        max-height: 100%;
    }
    .contacts-page .header{
        height: 20%;
        min-height:20%
    }
    .cont-footer-text{
        flex-direction: column;
        align-items: center;
    }
    .cont-footer-text-vector{
        padding-top: 10px;
        margin: 0;
        height: auto;
    }
    .cont-footer-logo{
        padding: 20px 0px 10px 0px;
        justify-content: center;
        height: auto;
    }
    .cont-footer-logo img{
        max-width: 90%;
    }
}