/* #root{
    height: auto;
    width: 100%;
    min-height: 100%;
} */
.back-to-top {
    position: fixed;
    bottom: 50vh;
    left: 20px;
    width: 50px;
    height: 50px;
    background-color: #3DAEA8;
    color: white;
    cursor: pointer;
    border-radius: 100px;
    display: flex;
}
.back-to-top a{
    width: 100%;
    height: 100%;
}
.container{
    height: auto;
    width: 100%;
    min-height: 100%;
    font-family: 'Montserrat', sans-serif;
}
.main{
    margin: 0;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;
    box-sizing: border-box;
    align-items: center;
}
.main-header{
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
}
.main-header-nav{
    width: 100%;
    height: 13%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}
.image-header{
    background-image: url('../../media/background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    grid-column: 1/10;
    grid-row: 1/13;
}
.image-header-gradient{
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    background: linear-gradient(89.89deg, #2A9F92 10.41%, rgba(255, 255, 255, 0) 85.89%);
}
.main-logo{
    height: 100%;
    display: flex;
    width: 35%;
    align-items: center;
    justify-content: flex-start;
}
.main-header-link{
    width: auto;
    height: 100%;
    display: flex;
    font-size: 1.1rem;
    align-items: flex-end;
    justify-content: space-between;
    color: #FFFFFF;
}
.link-text{
    width: auto;
    height: 70%;
    padding: 0px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.link-text a{
    width: auto;
    color: #FFFFFF;
    text-decoration: none;
    height: auto;
    box-sizing: border-box;
    border-bottom: 2px solid transparent
}
.link-text a:hover{
    border-bottom: 2px solid #FFFFFF;
    transition: 0.4s;
}
.link-text a:not(hover){
    border-bottom: 2px solid transparent;
    transition: 0.4s;
}
.language{
    grid-column: 10/13;
    grid-row: 1/3;
    display: flex;
    align-items: center;
}
.language-div{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.language-div-center{
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.language-ru{
    cursor: pointer;
    width: auto;
    padding: 10px 15px;
    height: auto;
    margin: 0px 10px;
    background-color: #FFFFFF;
    color: #2A9F92;
    border-radius: 10px;
    border: 2px solid #2A9F92;
}
#ru:hover,#en:hover{
    background-color: #d4fbff;
}
.main-header-content{
    width: 100%;
    height: 80%;
}
.main-header-title{
    width: 100%;
    height: 55%;
    display: flex;
    align-items: flex-end;
    color: #FFFFFF;
    font-size: 3.8rem;
    font-weight: 700;
    letter-spacing:4px;
    line-height: 4.6rem;
    padding-left: 8.5%;
    box-sizing: border-box;
}
.main-header-button{
    text-decoration: none;
    width: 100%;
    height: 20%;
    padding-left: 8.5%;
    padding-top: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}
.main-header-button button{
    display: flex;
    align-items: center;
    border-style: none;
    justify-content: space-evenly;
    min-width: 22%;
    border-radius: 10px;
    font-weight: 400;
    width: auto;
    height: auto;
    padding: 15px 55px;
    color: #288289;
    font-size: 1.4rem;
    cursor: pointer;
}
.main-header-button button:hover{
    background-color: #D3D3D3;
    transition: 0.3s;
}
.main-header-button button:not(hover){
    background-color: #FFFFFF;
    transition: 0.3s;
}
.mtmk{
    position:static;
    z-index: 1;
    grid-row: 4/12;
    grid-column: 7/13;
    box-sizing: border-box;
}
.mtmk img{
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}
.mtmk img:hover{
    transform: scale(1.15);
    transition: 0.3s;
}
.mtmk img:not(hover){
    transform: scale(1);
    transition: 0.3s;
}
.main-header-video{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    padding-left: 8.5%;
    box-sizing: border-box;
}
.video-div{
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
}
.video-div-text{
    color: #FFFFFF;
    font-size: 1.2rem;
    margin-left: 10px;
    border-bottom: 2px solid transparent;
}
.video-div:hover .video-div-text{
    border-bottom: 2px solid #FFFFFF;
    transition: 0.3s;
}
.video-div:not(hover) .video-div-text{
    border-bottom: 2px solid transparent;
    transition: 0.3s;
}

.main-mtmk{
    width: 100%;
    height: 100%;
    /* background-color: #2A9F92; */
}
.mtmk-title{
    width: 100%;
    max-width: 100%;
    height: 20%;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #18545B;
    font-size: 2.5rem;
    font-weight: 600;
}

.mtmk-image{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../media/mtmk_image_background.svg'),radial-gradient(70% 50% at 50% 60.61%, #74bbb3 0%, transparent 100%);
    background-repeat: no-repeat;
    background-position: 40% 10%;
    background-size: 100% 100%;

}
.image-with-buttons{
    width: auto;
    height: auto;
    display: flex;
    position: relative;
    background-color: transparent;
}
.image-with-buttons img{
    height: auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
}
.plus1{
    position: absolute;
    top: 48%;
    left: 9%;
    width: auto;
    height: auto;
}
.plus2{
    position: absolute;
    top: 10%;
    left: 66%;
    width: auto;
    height: auto;
}
.plus3{
    position: absolute;
    top: 66%;
    left: 59%;
    width: auto;
    height: auto;
}
.circle{
    width: auto;
    height: auto;
    border-radius: 20px;
}
.circle:hover{
    transform: scale(1.1);
    transition: 0.2s;
}
.circle:not(hover){
    transform: scale(1);
    transition: 0.2s;
}
.circle img:active{
    filter: brightness(90%);
    transition: 0.1s;
}
.css-1ri6ub7-MuiPaper-root-MuiCard-root{
    border-radius: 20px !important;
}

.main-about{
    width: 100%;
    height: 100%;

    background-image: url('../../media/about_background.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.main-about-gradient{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(12, 1fr);
    background: linear-gradient(89.89deg, rgba(42, 159, 146, 0.8) 0.08%, rgba(255, 255, 255, 0) 99.89%);
}
.main-about-title{
    grid-column: 2/8;
    grid-row: 2/4;
    display: flex;
    align-items: center;
    font-weight: 525;
    font-size: 3rem;
    color: #FFFFFF;
}
.main-about-text{
    grid-column: 2/8;
    grid-row: 4/10;
    width: 90%;
    color: #FFFFFF;
    font-size: 1.5rem;
    line-height: 2.5rem;
}
.main-about-image{
    grid-column: 8/12;
    grid-row: 3/11;
}
.main-about-image img{
    width: 100%;
    height: auto;
}
.main-about-button{
    grid-column: 2/8;
    grid-row: 11/12;
    display: flex;
    align-items: center;
    text-decoration: none;
}
.main-about-button button{
    width: auto;
    cursor: pointer;
    height: auto;
    background-color: transparent;
    color: #FFFFFF;
    font-size: 1.5rem;
    padding: 15px 50px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
}
.main-about-button button:hover{
    background-color: #FFFFFF;
    color: #288289;
    transition: 0.3s;
}
.main-about-button button:not(hover){
    transition: 0.3s;
}
.main-report{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;   
    flex-direction: column;
}

.main-report-title{
    width: 80%;
    height: 25%;
    display: flex;
    align-items: flex-end;
    font-weight: 525;
    font-size: 3rem;
    color: #18545B;
    line-height: 4rem;
}
.main-report-title-text{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}
.main-report-content{
    width: 100%;
    height: 75%;
    display: flex;
    justify-content: center;
    background-image: url('../../media/report_backgroung.png');
    background-repeat: no-repeat;
    background-size: cover;
}
.main-report-content-grad{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-image: linear-gradient(to bottom,transparent 80%, rgba(255,255,255,1));
}
.main-report-content-container{
    width: 80%;
    height: 100%;
}
.main-report-text{
    width: 50%;
    height: 30%;
    display: flex;
    align-items: center;
    font-weight: 525;
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #000;
}
.main-report-video{
    width: 50%;
    height:70%;
}
video{
    height: 200px;
    width: auto;
}
.main-advantages{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}
.main-advantegs-title{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #18545B;
    font-weight: 525;

}
.advantages{
    width: 80%;
    height: 85%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(8, 1fr);
    column-gap: 0px;
    row-gap: 40px;
    max-height: 85%;
    box-sizing: border-box;
    background: 
                radial-gradient(165% 88% at 24.5% 25% , rgba(42, 159, 147, 0.7) 0%, transparent 15%),
                radial-gradient(165% 88% at 75% 24.5% , rgba(42, 159, 147, 0.6)  0%, transparent 15%),
                radial-gradient(165% 90% at 24% 50% , rgba(42, 159, 147, 0.7)  1%, transparent 15%),
                radial-gradient(165% 90% at 75% 50% , rgba(42, 159, 147, 0.7)  1%, transparent 15%),
                radial-gradient(165% 90% at 75% 77% , rgba(42, 159, 147, 0.7)  1%, transparent 15%),
                radial-gradient(165% 90% at 24% 77% , rgba(42, 159, 147, 0.7)  1%, transparent 15%)
    /* background: radial-gradient(ellipse at center, #2A9F93, transparent 70.5%);
    background-size:50% 35%; */
}
#item1{
    grid-column: 1/7;
    grid-row: 2/4;
}
#item2{
    grid-column: 7/13;
    grid-row: 2/4;
}
#item3{
    grid-column: 1/7;
    grid-row: 4/6;
}
#item4{
    grid-column: 7/13;
    grid-row: 4/6;
}
#item5{
    grid-column: 1/7;
    grid-row: 6/8;
    display: flex;
    justify-content: center;
}
#item6{
    grid-column: 7/13;
    grid-row: 6/8;
    display: flex;
    justify-content: center;
}
.adv-gradient{
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
}
.advantage-item{
    height: auto;
    min-height: 85%;
    max-height: 100%;
    display: flex;
    align-items: center;
    /* border: 2px solid rgb(42, 159, 147); */
    background-color: #FFFFFF;
    border-radius: 10px;
    width: 100%;
}

.advantage-icon{
    display: flex;
    height: 100%;
    width: 30%;
    justify-content: center;
    align-items: center;
    max-height: 100%;
    max-width: 30%;
}
.advantage-text{
    width: 65%;
    height: 100%;
    display: flex;
    align-items: center;
    color: #000;
    font-size: 1.1rem;
}
.advantage-text a{
    color: #18545B;
}
.advantage-item>.advantage-icon img{
    max-height: 50%;
    max-width: 100%;
    height: 50%;
    width: 40%;
}
#item6>.advantage-item>.advantage-icon>img{
    width: 60%;
}

.main-news{
    width: 100%;
    height: 100%;
}
.main-news-title{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #18545B;
    font-weight: 525;
}
.main-news-content{
    background-image: url('../../media/news_background.svg');
    background-repeat: no-repeat;
    background-position: 100% 145%;
    background-size: 100% 70%;
    width: 100%;
    height: 75%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.news-slider{
    width: 85%;
    height: 70%;
    display: flex;
    justify-content: space-evenly;
    /* background-color: #288289; */
}
.card-main{
    width: 30%;
    height: 95%;
    text-decoration:none;
    color: #000;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1);
}
.card-main-image{
    width: 100%;
    height: 60%;
    box-sizing: border-box;
}
.card-main-text{
    padding: 10px 15px;
    width: 100%;
    height: auto;
    line-height: 25px;
    box-sizing: border-box;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    z-index: 1;
    text-overflow: ellipsis;
    padding-bottom: 0px;
}
.card-main-image img{
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: auto;
}
.card-main>.text-date-info>.small-card-info{
    padding: 3% 5% 3% 5%;
}
.text-date-info{
    height: auto;
    max-height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
}

.newcard{
    background-color: #FFFFFF;
    width: 30%;
    height: 100%;
    padding-top: 66px;
    display: flex;
    flex-direction: column;
}
.newcard-image{
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
}
.newcard-text{
    width: 100%;
    height: 100%;
}
.news-slider-footer{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.news-slider-button{
    width: 85%;
    height: 100%;
    display: flex;
    padding-top: 40px;
    align-items: center;
    text-decoration: none;
}
.news-slider-button button{
    width: auto;
    height: auto;
    padding: 15px 45px;
    border-radius: 10px;
    border-style: none;
    background-color: transparent;
    border: 1px solid #FFFFFF;
    font-size: 1.1rem;
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
}
.news-slider-button button:hover{
    background-color: #FFFFFF;
    color: #88CDC3;
    transition: 0.3s;
}
.news-slider-button button:not(hover){
    transition: 0.3s;
}
.main-materials{
    width: 100%;
    height: 80%;
    background: url('../../media/materials.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 90% 100%;
}
.main-materials-title{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 3rem;
    color: #18545B;
    font-weight: 525;
}
.main-materials-content{
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
}
.material-content{
    width: 85%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.material{
    width: 30%;
    height: auto;
    max-height: 50%;
    border-radius: 10px;
    font-size: 1.5rem;
    box-sizing: border-box;
    border: 2px solid #18545B;
    overflow: hidden;
    background-color: #18545B;
}
.material img{
    height: auto;
    max-height: 100%;
    width: 100%;
}
.main-partners{
    width: 100%;
    height: 70%;
}
.main-partners-title{
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    color: #18545B;
    font-weight: 525;
}
.partners-content{
    width: 100%;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../media/partners.svg');
    background-size: 100% 210%;
    background-repeat: no-repeat;
}
.parnter-content-grid{
    width: 75%;
    height: 100%;
    /* background-color: #18545B; */
    display: grid;
    box-sizing: border-box;
    padding-top: 80px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
}
.partner-card{
    grid-column: 1span;
    grid-row: 1span;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.partner-card img{
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    width: auto;
    filter: grayscale(1) brightness(1);
}
.card-main:hover .card-button button{
    background-color: #2A9F92;
    color: #FFFFFF;
    transition: 0.3s;
}
.card-main:hover .card-main-image img{
    transform: scale(1.05);
    transition: 0.3s;
}
.card-main:not(hover) .card-main-image img{
    transition: 0.3s;
}
.card-main:not(hover) .card-button button{
    transition: 0.3s;
}


@media screen and (max-width: 1440px){
    .main-header-link{
        width: auto;
    }
    .link-text a{
        padding: 0px 5px;
    }
    .link-text{
        box-sizing: border-box;
    }
    .mtmk{
        grid-column:8/13;
        grid-row: 6/13;
    }
    .main-report-title-text{
        width: 80%;
    }
}
@media screen and (max-width: 1440px){
    .main-header-title{
        font-size: 3.5rem;
    }
    .small-card-info{
        padding: 3% 5% 3% 5%;
    }
    .text-date-info{
        padding: 0;
    }
}
@media screen and (max-width: 1024px){
    .main-header-nav{
        flex-direction: column;
    }
    .main-header-link,.main-logo{
        height: auto;
    }
    .main-header-nav{
        justify-content: center;
        height: 15%;
    }
    .main-header-title{
        font-size: 3.2rem;
        align-items: center;
    }
    .main-header-button{
        align-items: center;
    }
    .image-header-gradient{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .mtmk{
        grid-column: 7/13;
        grid-row: 7/13;
    }
    .image-with-buttons{
        width: 90%;
    }
    .main-about-title{
        grid-column: 2/13;
        grid-row: 1/3;
        font-size: 2.5rem;
    }
    .main-about-text{
        grid-column: 2/13;
        grid-row: 3/9;
        font-size:1.2rem
    }
    .main-about-image{
        grid-row: 8/12;
        border-radius: 20px;
        grid-column: 7/12;
        overflow: hidden;
    }
    .main-about-button{
        align-items: flex-end;
    }
    .main-report-title{
        width: 85%;
        height: 20%;
    }
    .main-report-title-text{
        width: 100%;
        align-items: center;
    }
    .main-report-content-container{
        width: 85%;
    }
    .main-report-text{
        width: 100%;
    }
    .main-report-video{
        width: 100%;
    }
    .advantages{
        width: 90%;
    }
    .advantage-item{
        width: 90%;
    }
    .advantage-icon{
        width: 20%;
    }
    .advantage-icon img{
        max-width: 100%;
        max-height: 100%;
    }
    .advantage-text{
        width: auto;
        max-width: 80%;
        padding-left: 10px;
        box-sizing: border-box;
        font-size: 1.1rem;
    }
    .main-news-content{
        height: 75%;
    }
    .main-news{
        height: 80%;
    }
    .news-slider{
        width: 95%;
    }
    .main-news-content{
        background-position: 100% 142%;
        background-size: 138% 76%;
    }
    .main-materials-title{
        align-items: center;
    }
    .card-main-text{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        z-index: 1;
        text-overflow: ellipsis;
        padding: 5px 10px;
    }
    .main-materials{
        height: 50%;
    }
    .material-content{
        width: 95%;
    }
    .main-partners{
        height: 50%;
    }
    .parnter-content-grid{
        width:90%;
    }
}

@media screen and (max-width: 800px){
    .main-logo{
        width: auto;
    }
    .main-header-title{
        font-size: 3rem;
    }
    .main-mtmk{
        height: 80%;
    }
    .mtmk-title{
        height: 30%;
    }
    .mtmk-image{height: 70%;}
    .main-about{
        height: 120%;
    }
    .main-about-image{
        display: flex;
        align-items: flex-end;
    }
    .main-report{
        height: 80%;
    }
    .main-report-title{
        height: 25%;
    }
    .main-news-title{
        font-size: 2.3rem;
    }
    .main-report-title-text{
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .main-report-video{
        box-sizing: border-box;
        max-height: 100%;
    }
    .main-report-video div{
        max-height: 100%;
    }
    .main-report-text{
        font-size:1.2rem
    }
    .main-advantegs-title{
        font-size: 2.5rem;
    }
    .card-main{
        z-index: 1;
        height: 100%;
    }
    .main-news{
        height: 60%;
    }
    .main-materials-title,.main-partners-title{
        font-size: 2.5rem;
    }
    .main-news-content{
        height: 85%;
    }
    .back-to-top{
        width: 35px;
        height: 35px;
    }
    .back-to-top a svg{
        max-width: 100%;
        max-height: 100%;
    }
    .small-card-info{
        flex-direction: column;
    }
    .text-date-info{
        padding: 0;
    }
    .card-main>.text-date-info>.small-card-info>.card-button button{
        padding: 5px 10px;
    }
    .card-main-text{
        font-size: 10px;
        line-height: 17px;
    }
}

@media screen and (max-width: 425px){
    .language-ru{
        padding: 5px 10px;
        font-size: 12px;
        margin: 0px 5px;
    }
    .main-header-link{
        font-size: 10px;
    }
    .main-logo img{
        max-width: 80%;
    }
    .main-logo{        
        justify-content: center;
    }
    .main-header-title{
        font-size: 1.5rem;
        line-height: 3.5rem;
        height: 40%;
        letter-spacing: normal;
    }
    .language{
        justify-content: center;
    }
    .mtmk{
        grid-row: 6/12;
        grid-column: 4/13;
    }
    .main-header-button{
        height: 40%;
        align-items: flex-end;
    }
    .main-header-button button{
        padding: 5px 15px;
        font-size: 1rem;
    }
    .main-header-button button svg{
        max-width: 17%;
    }
    .main-header-video{
        height: 5%;
        padding-top: 40px;
    }
    .mtmk-title{
        font-size: 1.7rem;
    }
    .plus1,.plus2,.plus3{
        width: 30px;
    }
    .plus1{
        top: 39%;
        left:5%
    }
    .plus2{
        top: 7%;
        left:64%
    }
    .main-about-title{
        grid-row: 1/2;
        font-size: 1.7rem;
    }
    .main-about-text{
        line-height: 2rem;grid-row: 2/9;
    }
    .main-about-image{
        display: none;
    }
    .main-report-title{
        height: 20%;
    }
    
    .main-report-title-text{
        font-size: 1.7rem;
        line-height: 2rem;
    }
    .main-report-text{
        font-size: 1rem;
        line-height: 1.7rem;
        height: 35%;
    }
    .main-report-video div{
        max-width: 100%;
    }
    .main-report{height:100%}
    .main-advantegs-title{
        font-size: 1.7rem;
    }
    #item1{
        grid-column: 2/12;
        grid-row: 2/3;
    }
    #item2{
        grid-column: 2/12;
        grid-row: 3/4;
    }
    #item3{
        grid-column: 2/12;
        grid-row: 4/5;
    }
    #item4{
        grid-column: 2/12;
        grid-row: 5/6;
    }
    #item5{
        grid-column: 2/12;
        grid-row: 6/7;
    }
    #item6{
        grid-column: 2/12;
        grid-row: 7/8;
    }
    .advantage-icon img{
        max-width: 70%;
        max-height: 100%;
    }
    .advantage-text{
        font-size: 13px;
    }
    .advantage-text a{
        color: #18545B;
    }
    .advantage-item{
        width: 100%;
        max-width: 100%;
        min-height: 85%;
        padding: 5px 5px;
    }
    .advantages{
        width: 100%;
        grid-template-rows: repeat(9, 1fr);
        background: radial-gradient(330% 52% at 50% 11% , rgba(42, 159, 147, 0.7) 0%, transparent 15%), 
                    radial-gradient(330% 60% at 50% 27% , rgba(42, 159, 147, 0.7) 0%, transparent 15%),
                    radial-gradient(330% 52% at 50% 43% , rgba(42, 159, 147, 0.7) 0%, transparent 15%), 
                    radial-gradient(330% 55% at 50% 58% , rgba(42, 159, 147, 0.7) 0%, transparent 15%), 
                    radial-gradient(330% 62% at 50% 74% , rgba(42, 159, 147, 0.7) 0%, transparent 15%)
    }
    .main-news-title{
        font-size: 1.7rem;
    }
    .main-news{
        background: linear-gradient(to left, transparent 60%, white 80%),linear-gradient(to top, transparent 70%, white 90%),linear-gradient(to bottom, transparent 70%, white 90%),url('../../media/news_background2.svg');
        background-repeat: no-repeat;
        background-size: cover;
        height: 70%;
    }
    .news-slider{
        flex-direction: column;
        height: 85%;
    }
    .main-news-content{
        background: none;
        height: 85%;
        box-sizing: border-box;
        justify-content: flex-start;
    }
    .card-main{
        width: 100%;
        border-radius: 10px;
        height: auto;
        flex-direction: row;
        background-color: #fff;
    }
    
    .card-main-image{
        max-height: 100%;
        height: auto;
        max-width: 40%;
        width: 40%;
    }
    .main-advantages{
        height: auto;
    }
    .advantages{
        grid-template-rows: repeat(8,1fr);
        row-gap: 50px;
        background: radial-gradient(330% 45% at 50% 18% , rgba(42, 159, 147, 0.7) 0%, transparent 15%),
        radial-gradient(330% 45% at 50% 31% , rgba(42, 159, 147, 0.7) 0%, transparent 15%),
        radial-gradient(330% 45% at 50% 44% , rgba(42, 159, 147, 0.7) 0%, transparent 15%),
        radial-gradient(330% 45% at 50% 57% , rgba(42, 159, 147, 0.7) 0%, transparent 15%),
        radial-gradient(330% 45% at 50% 70% , rgba(42, 159, 147, 0.7) 0%, transparent 15%),
        radial-gradient(330% 45% at 50% 83% , rgba(42, 159, 147, 0.7) 0%, transparent 15%);
    }
    .card-main-image img{
        width: 100%;
        height: auto;
    }
    .text-date-info{
        width: 60%;
        max-height:100%;
    }
    .card-main-image img{
        max-height: 100%;
        width: auto;
        height: 100%;
    }
    .card-main>.text-date-info>.small-card-info{
        flex-direction: row;
    }
    .text-date-info{
        justify-content: space-between;
    }
    .card-main>.text-date-info>.small-card-info .card-date{
        font-size: 10px;
    }
    .card-main>.text-date-info>.small-card-info>.card-button > button{
        font-size: 10px;
    }
    .news-slider-button button{
        font-size: 15px;
        border-radius: 10px;
        border: 1px solid #18545B;
        color: #18545B;
        padding: 10px 20px;
    }
    .news-slider-button{
        padding: 0;
        height: 100%;
        width: 95%;
    }
    .news-slider-footer{
        height: 10%;
        width: 100%;
    }
    .material-content{
        flex-direction: column;
    }
    .main-materials{
        height: 80%;
    }
    .material{
        width: 60%;
    }
    .partners-content{
        box-sizing: border-box;
    }
    #partner2{
        grid-column: 2/3;
    }
    #partner1{
        grid-column: 3/4;
        grid-row: 2/3;
    }
    #partner3{
        grid-column: 4/5;
    }
    #partner4{
        grid-column: 2/3;
        grid-row: 3/4;
    }
    #partner5{
        grid-column: 4/5;
        grid-row: 3/4;
    }
    .parnter-content-grid{
        padding-top: 20px;
        max-height: 100%;
        box-sizing: border-box;
    }
    .main-partners{
        height: 40%;
    }
}
@media screen and (max-width: 425px){
    .link-text a{
        padding: 0px 3px;
    }
    .main-header-title,.main-header-button{
        padding-left: 4%;
    }
    .main-header-video{
        padding-left: 3%;
    }
    .main-header-link{
        font-size: 9px;
    }
    .link-text a{
        padding: 0px 1px;
    }
    .link-text{
        padding: 0px 5px;
    }
}
@media screen and (max-width: 390px){
    .main-header-link{
        font-size: 8px;
    }
    .link-text a{
        padding: 0px 1px;
    }
    .link-text{
        padding: 0px 5px;
    }
}
@media screen and (max-width: 320px){
    .main-header-link{
        font-size: 8px;
    }
    .link-text a{
        padding: 0px 1px;
    }
    .link-text{
        padding: 0px 3px;
    }
}