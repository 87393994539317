.news-page{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}
.news-first-block{
    width: 100%;
    height: auto;
    background-image: url('../../media/rectangle.png');
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
}
.first-block-gradient{
    width: 100%;
    padding-top: 50px;
    height: 100%;
    display: flex;
    max-height: 100%;
    justify-content: center;
    box-sizing: border-box;
    background: linear-gradient(90deg, #FFFFFF 8.96%, rgba(255, 255, 255, 0) 44.01%), linear-gradient(180deg, #FFFFFF 7.36%, rgba(217, 217, 217, 0) 50%),linear-gradient(360deg, #ffffff 0%,transparent 15%, transparent 30%);
    /* linear-gradient(360deg, #ffffff 5%, transparent 20%),linear-gradient(180deg, #ffffff 5%, transparent 50%),linear-gradient(90deg, #ffffff 20%, transparent 40%,transparent 90%); */
}
.css-w88nxk{
    border-radius: 20px;
}
.first-block-content{
    width: 75%;
    height: 100%;
    max-height: 98.5%;
    display: grid;
    grid-template-columns: repeat(6,14.2%);
    grid-template-rows: repeat(7,12%);
    column-gap: 40px;
    row-gap: 30px;
    box-sizing: border-box;
    margin-bottom: 20px;
}
.news-card{
    grid-column: 3 span;
    grid-row: 4 span;
    text-decoration: none;
    color: #000;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    box-sizing: border-box;
}
.news-card:hover .card-text-info button{
    background-color: #2A9F92;
    color: #FFFFFF;
    transition: 0.3s;
}
.news-card:not(hover) .card-text-info button{
    transition: 0.3s;
}
.news-card:hover .news-card-image img{
    transform: scale(1.04);
    transition: 0.3s;
}
.news-card:not(hover) .news-card-image img{
    transform: scale(1);
    transition: 0.3s;
}
.news-card-image{
    width: 100%;
    height: 70%;
    max-height: 70%;
    overflow: hidden;
}
.news-card-image img{
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    height: auto;
}
.news-card-text{
    height: 30%;
    width: 100%;
    padding: 3% 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

}
.card-text-info{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 5% 2% 5%;
    box-sizing: border-box;
    font-size: 16px;
    color: #A3A3A3;
}
.card-button button{
    background-color: #FFFFFF;
    color: #2A9F92;
    border: 1px solid #2A9F92;
    border-radius: 8px;
    padding: 13px 25px;
}
.card-text-text{
    width: 100%;
    height: auto;
    box-sizing: border-box;
    font-size: 18px;
    line-height: 25px;
    padding: 0% 5%;
}

/*Начало стилизации маленьких карточек*/

.small-news-card{
    grid-column: 2 span;
    grid-row: 3 span;
}
.group-image-text{
    width: 100%;
    height: 85%;
    max-height: 85%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.small-news-card-content{
    box-sizing: border-box;
    text-decoration: none;
    color: #000;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 20px -1px rgba(0, 0, 0, 0.1);
    display: flex;
    background-color: #ffffff;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100%;
}
.small-news-card-image{
    height: 65%;
    max-height: 100%;
    width: 100%;
    position: relative;  
}
.small-news-card-image img{
    object-position: 20% 10%;
    width: 100%;
    height: auto;
    max-height: 100%;
}
.small-news-card:hover .small-news-card-image img{
    transform: scale(1.04);
    transition: 0.3s;
}
.small-news-card:not(hover) .small-news-card-image img{
    transform: scale(1);
    transition: 0.3s;
}
.small-news-card:hover .card-button button{
    background-color: #2A9F92;
    color: #FFFFFF;
    transition: 0.3s;
}
.small-news-card:not(hover) .card-button button{
    transition: 0.3s;
}
.small-card-group{
    height: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.small-card-text{
    box-sizing: border-box;
    font-size: 15px;
    line-height: 25px;
    padding: 3% 5% 0% 5%;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    z-index: 1;
    text-overflow: ellipsis;
}
.small-card-info{
    height: auto;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 0% 5% 3% 5%;
}
/*Конец стилизации маленьких карточек*/
.main-news-block{
    width: 100%;
    height: auto;
    min-height: 50%;
    display: flex;
    justify-content: center;
    background: linear-gradient(140.27deg, #FFFFFF 55%, rgba(196, 196, 196, 0)80%,transparent 100%), linear-gradient(217.6deg,#FFFFFF 55%, rgba(196, 196, 196, 0) 10%,transparent 100%),linear-gradient(230.27deg, #FFFFFF 55%, rgba(196, 196, 196, 0)80%,transparent 100%),url('../../media/rectangle.png');
    background-repeat: no-repeat;
    background-position: 50% 100%;
    background-size: 100% 140%;    
}
.main-news-block-gradient{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 25%;
    background:url('../../media/news_figure.svg');
    background-repeat: no-repeat;
    background-position: 220% 260%;
    background-size: 90% 95%;  
}
.big-card-button button{
    background-color: #FFFFFF;
    color: #2A9F92;
    border: 1px solid #2A9F92;
    border-radius: 8px;
    padding: 13px 25px;
}

.big-card-date{
    width: auto;
    height: auto;
    font-size: 16px;
    color: #A3A3A3;
}
.card-date{
    width: auto;
    height: auto;
    font-size: 14px;
    color: #A3A3A3;
    cursor: pointer;
}
.card-button button{
    width: auto;
    height: auto;
    padding: 10px 15px;
    cursor: pointer;
}
.main-news-block-content{
    padding-top: 40px;
    width: 75%;
    height: 100%;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(6,14.2%);
    grid-template-rows: repeat(auto-fill,12%);
    column-gap: 40px;
    row-gap: 30px;
}



@media screen and (max-width: 1024px){
    .first-block-content{
        width: 90%;
        box-sizing: border-box;
        grid-template-columns: repeat(6,13%);
        grid-template-rows: repeat(7,13%);
    }
    .small-news-card-image{
        max-height: 50%;
    }
    .news-card-image{
        max-height: 60%;
    }
    .news-card-text{
        height: 40%;
    }
    .small-card-text{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        z-index: 1;
        text-overflow: ellipsis;
    }

}
@media screen and (max-width: 880px){
    .first-block-content{
        grid-template-columns: repeat(6,12%);
        grid-template-rows: repeat(8,10%);
    }
    .card-text-text{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .small-news-card{
        grid-column: 3 span;
        grid-row: 4 span;
    }
}
@media screen and (max-width: 550px){
    .first-block-content{
        grid-template-columns: repeat(6,8%);
        grid-template-rows: repeat(8,6%);
    }
    .news-card,.small-news-card{
        grid-column: 6 span;
        z-index: 1;
    }
    .first-block-gradient{
        height: auto;
        max-height: none;
        background: linear-gradient(90deg, #FFFFFF 8.96%, rgba(255, 255, 255, 0) 44.01%),
        linear-gradient(180deg, #FFFFFF 7.36%, rgba(217, 217, 217, 0) 30%),
        linear-gradient(360deg, #ffffff 40%,transparent 50%);
    }
}
@media screen and (max-width: 445px){
    .first-block-content{
        grid-template-columns: repeat(6,7%);
    }
    .first-block-gradient{
        background: linear-gradient(90deg, #FFFFFF 8.96%, rgba(255, 255, 255, 0) 44.01%),
        linear-gradient(180deg, #FFFFFF 7.36%, rgba(217, 217, 217, 0) 20%),
        linear-gradient(360deg, #ffffff 55%,transparent 60%);
    }
}
@media screen and (max-width: 320px){
    .first-block-content{
        grid-template-columns: repeat(6,5%);
        grid-template-rows: repeat(8,5%);
    }
    .first-block-gradient{
        background: linear-gradient(90deg, #FFFFFF 8.96%, rgba(255, 255, 255, 0) 44.01%),
        linear-gradient(180deg, #FFFFFF 7.36%, rgba(217, 217, 217, 0) 20%),
        linear-gradient(360deg, #ffffff 55%,transparent 60%);
    }
    .card-text-text{
        font-size: 15px;
    }
    .big-card-date{
        font-size: 13px;
    }
    .big-card-button button{
        padding: 10px 15px;
    }
}