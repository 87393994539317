.header{
    width: 100%;
    height: 13%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden;
    box-sizing: border-box;
}
.header-content{
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-logo{
    width: auto;
}
.header-nav-lang{
    width: 64%;
    height: 100%;
    display: flex;
    align-items: center;
}
.header-nav a{
    text-decoration: none;
    margin: 0px 25px;
    color: #18545B;
    font-size: 1.05rem;
    border-bottom: 2px solid transparent;
    cursor: pointer;
}
.header-nav a:hover{
    border-bottom: 2px solid #18545B;
    transition: 0.4s;
}
.header-nav a:not(hover){
    border-bottom: 2px solid transparent;
    transition: 0.4s;
}

.header-nav{
    padding-top: 30px;
    padding-right: 20%;
}
.header-lang{
    height: 100%;
    width: auto;
    padding-top: 20px;
}

@media screen and (max-width: 1450px){
    .header-nav-lang{
        width: 70%;
    }
    .header-nav{
        padding-top: 30px;
        padding-right: 20px;
    }
}
@media screen and (max-width: 1024px){
    .header{
        height: 16%;
    }
    .header-content{
        width:100%;
        flex-direction: column;
        justify-content:flex-start
    }
    .header-nav-lang{
        width: auto;
        height: auto;
    }
    .header-logo{
        padding-top: 15px;
    }
    .header-nav{
        padding-top: 0px;
    }
    .language{
        padding: 0;
    }
}
@media screen and (max-width: 800px){
    .header-nav{
        padding: 0;
    }
    .header{
        height: 25%;
        min-height: 25%;
    }
    .language{
        padding-bottom: 15px;
    }
    .header-nav a{
        margin: 0px 10px;
    }
    .header-content{
        box-sizing: border-box;
    }
    .header-nav-lang{
        flex-direction:column-reverse;
        box-sizing: border-box;
    }
}
@media screen and (max-width: 550px){

}
@media screen and (max-width: 425px){
    .header >.language-ru,.language-en{
        padding: 5px 10px;
    }
    .header-nav a{
        font-size: 10px;
    }
    .header-logo img{
        transform: scale(0.75);
    }
}
@media screen and (max-width: 320px){
    .header-nav a{
        font-size: 8px;
    }
}