.single-news-page{
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
}
.single-full-content{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background: radial-gradient(ellipse 100% 400px 
    at 200px 350px,
    #2A9F92 25%,
    transparent 80%);
}
.single-background-image{
    width: 100%;
    height: auto;
    padding-bottom: 300px;
    background:url('../../media/news_figure.svg');
    background-repeat: no-repeat;
    background-position: 100% 1060%;
    background-size: 90% 95%; 
}
.single-content{
    width: 70%;
    height: auto;
    display: flex;
}
.single-main-content{
    width: 76%;
    height: auto;
    display: flex;
    flex-direction: column;
}
.single-main-allnews{
    width: 100%;
    height: auto;
    display: flex;
    padding-bottom: 40px;
}
.single-allnews-title{
    width: auto;
    height: auto;
    font-size: 18px;
    border-radius: 10px;
    border: 1px solid #18545B;
    color: #18545B;
    padding: 15px 22px;
    text-decoration: none;
}
.single-allnews-title:hover{
    background-color: #fff;
    transition: 0.1s;
}
.single-allnews-title:not(hover){
    background-color: transparent;
    transition: 0.1s;
}
.single-main-card{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ffffff;
    box-shadow: 0px 0px 40px -1px rgba(0, 0, 0, 0.2);
}
.main-card-image{
    width: auto;
    max-width: 100%;
    justify-content: center;
    height: auto;
    display: flex;
}
.main-card-image img{
    width: 100%;
    height: auto;
    max-width: 100%;
}
.main-card-title{
    font-size: 18px;
    width: auto;
    height: auto;
    max-width: 100%;
    color: #000;
    padding:20px 50px;
}
.main-card-date{
    width: 100%;
    height: auto;
    font-size: 15px;
    padding-left: 50px;
    color: #A3A3A3;
    padding-bottom: 20px;
}
.single-page-body{
    padding-top: 20px;
    width: 90%;
}
.single-page-text{
    padding-bottom: 20px;
    line-height: 150%;
}
.single-page-text-list{
    line-height: 150%;
}
.single-page-text a{
    color: #18545B;
}
.p-video{
    width: 100%;
    display: flex;
    justify-content: center;
}
.single-page-image{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-bottom: 20px;
}
.single-page-image img{
    width: 70%;
}

.single-secondary-content{
    width: 34%;
    height: auto;
    display: flex;
    flex-direction: column;

}
.loading{
    width: 100%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.read-more-block{
    width: 100%;
    height: auto;
    display: flex;
    padding-bottom: 40px;
}
.read-more-title{
    width: auto;
    height: auto;
    font-size: 18px;
    border-radius: 10px;
    color: #18545B;
    padding: 15px 0px;
    text-decoration: none;
}

.read-more-card{
    width: 100%;
    height: auto;
    text-decoration:none;
    color: #000;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    margin-bottom: 20px;
    box-shadow: 0px 0px 40px -1px rgba(0, 0, 0, 0.2);
    text-decoration: none;
    cursor: pointer;
}
.read-more-image img{
    width: 100%;
    max-width: 100%;
    height: auto;
}
.read-more-text{
    font-size: 16px;
    padding: 15px 20px;
    color: #000;
}
.read-more-info{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    height: auto;
    justify-content: space-between;
    padding: 15px 20px;
}
.read-more-date{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #A3A3A3;
}
.read-more-read{
    width: auto;
    height: auto;
    font-size: 14px;
    padding: 5px 10px;
    border: 1px solid #2A9F92;
    border-radius: 10px;
    color: #2A9F92;
    font-weight: 600;
}
.read-more-card:hover .read-more-read{
    background-color: #2A9F92;
    color: #FFFFFF;
    transition: 0.3s;
}
.read-more-card:not(hover) .read-more-read{
    transition: 0.3s;
}
.read-more-card:hover .read-more-image img{
    transform: scale(1.05);
    transition: 0.3s;
}
.read-more-card:not(hover) .read-more-image img{
    transition: 0.3s;
}



@media screen and (max-width: 1024px){
    .single-content{
        width: 90%;
    }
    .read-more-read{
        text-align: center;
    }
    .read-more-info{
        flex-direction: column;
        align-items: center;
    }
    .read-more-date{
        padding: 10px 0px;
    }
    .single-full-content{
        background: radial-gradient(ellipse 100% 250px at 100px 350px, #2A9F92 25%, transparent 80%);
    }
}
@media screen and (max-width: 800px){
    .single-full-content{
        background: radial-gradient(ellipse 100% 200px at 100px 300px, #2A9F92 25%, transparent 80%);
    }
    .single-allnews-title{
        padding: 10px 15px;
    }
    .read-more-title{
        padding: 10px 0px;
    }
    .main-card-title{
        padding: 15px 15px;
    }
    .main-card-date{
        padding: 0px 0px 15px 15px;
    }
}
@media screen and (max-width: 550px){

    .single-content{
        flex-direction: column;
        align-items: center;
    }
    .single-full-content{
        background: radial-gradient(ellipse 120% 200px at 40px 250px, #2A9F92 25%, transparent 80%);
    }
    .single-background-image{
        padding-bottom:150px;
        background-position:100% 990% 
    }
    .single-main-content{
        width: 100%;
        align-items: center;
    }
    .single-secondary-content{
        width: 90%;
        justify-content: center;
        padding-top: 30px;
    }
    .read-more-info{
        flex-direction: row;
    }
    .read-more-block{
        padding-bottom: 20px;
        justify-content:center ;
    }
    .single-content{
        width: 95%;
    }
    .single-main-allnews{
        width: 90%;
    }
}
